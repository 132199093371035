import React from 'react';

class TermsAndConditionsPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <div>
                    <h3>1. Overview</h3>
                    <p>
                        1.1&nbsp;&nbsp;Techlabs Global (Pvt) Limited whose registered office is at 9-B Horton Place, Colombo 7, Sri Lanka
                        (hereinafter referred to as “us”, “we” or “our”) owns and operates technology platform with a
                        website at http://sports.divolgodigital.com (the “Website”) and a mobile application titled “Divolgo” (the “App”) through
                        which advertisers can bid via auction (“Online Auction”) or purchase in advance (“Online Purchase”) advertisements slots for static
                        advertisements at specified times on digital displays, including wearables, (the “Service(s)”) at sporting event(s) (“Event(s)”).
                    </p>
                    <p>
                        1.2&nbsp;&nbsp;These terms and conditions (hereinafter referred to as <b>“Terms”</b>) govern access to and the terms of use of the Services.
                        Please read them carefully. These Terms form a binding contractual agreement between you, the user of the Services
                        (hereinafter referred to as <b>“you”</b>), and us. By using our Services, you are agreeing to these terms.
                    </p>
                    <p>
                        1.3&nbsp;&nbsp;If you do not agree to the Terms, as amended from time to time, in whole or in part, you must not access the Website,
                        the App or use any part of the Services. We reserve the right to amend, change, update or replace any part of these Terms by posting updates
                        and/or changes to our Website. It is your responsibility to check this page periodically for changes.
                    </p>
                    <p>
                        1.4&nbsp;&nbsp;By using the Services, you agree to, at all times, be in compliance with these Terms, our Privacy Policy and any other terms,
                        policies or notices published on the Website.
                    </p>
                </div>

                <div>
                    <h3>2. Registration and Account Creation</h3>
                    <p>
                        2.1&nbsp;&nbsp;Users cannot use the Services without having first registered for the Services by creating an account (the “Account”).
                    </p>
                    <p>
                        2.2&nbsp;&nbsp;In order to utilize the Online Auction or Online Purchase features of the platform, via the Website or the App, you must be
                        registered, with payment instructions selected and a default image for your static advertisement approved.
                    </p>
                    <p>
                        2.3&nbsp;&nbsp;Your registration with the Service is subject to the following conditions:
                    </p>
                    <ol type="a">
                        <li>
                            Any user must be aged 18 or over. By registering for the Services, you represent and warrant that you are aged 18 or over.
                        </li>
                        <li>
                            You must be legally capable of entering into this legally binding agreement.
                        </li>
                        <li>
                            If you are acting on behalf of an institution, business, organization, agency or other entity (an “Organization”), you will be responsible
                            for ensuring that: (i) you have authority to bind that Organization to these Terms, and your agreement to these Terms will be treated as the
                            agreement of the Organization, and (ii) any individual or entity to whom you provide access to the Services is made aware of and complies with
                            these Terms. In that case, “you” and “your” in these Terms shall refer to the Organization you represent.
                        </li>
                    </ol>
                    <p></p>
                    <p>
                        2.4&nbsp;&nbsp;You are responsible for the activity that happens on or through your Account. You are also responsible for protecting your
                        Account by keeping your password confidential.
                    </p>
                </div>

                <div>
                    <h3>3. Use of Service</h3>
                    <p>
                        3.1&nbsp;&nbsp;Subject to these Terms, we hereby grant to you, a personal, non-transferable, non-sublicensable, non-exclusive,
                        limited right to access and use the Services for the purpose of Online Auction or Online Purchase services and uploading the content of
                        your advertisement (“Content”) through the Divolgo platform for broadcast.
                    </p>
                    <p>
                        3.2&nbsp;&nbsp;We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are
                        investigating suspected misconduct.
                    </p>
                    <p>
                        3.3&nbsp;&nbsp;You understand and agree that you are using the Services at your own risk.
                    </p>
                    <p>
                        3.4&nbsp;&nbsp;Sole responsibility for the Content lies with you. We may review Content to determine whether it is illegal or violates our
                        policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not
                        necessarily mean that we review Content and we are under no obligation to do so. In respect of Fixed Exposure services you may be informed
                        that your content has been removed or refused you will be given the opportunity to upload different Content in its place. No refunds shall be given
                        in respect of removed or refused Content.
                    </p>
                    <p>
                        3.5&nbsp;&nbsp;When creating an Account and using the Services, you represent and warrant to us that you are the sole owner or have all
                        necessary rights to represent the brand owners and use any Content used in the advertisements you submit to us, and you represent that you
                        shall hold us immune for any intellectual property infractions you may incur. The forgoing representation includes, but is not limited to a
                        representation and warranty that you own or have the necessary rights (including also any necessary releases) to grant all rights granted
                        below in relation to any persons, places or intellectual property pictured in any photographic content that you provide. In addition, if
                        you post or otherwise provide any content that is protected by intellectual property laws, you represent that you have obtained any
                        necessary permissions or releases from the applicable intellectual property owner and you may be required to provide proof of the same during
                        the Account creation process or at any time thereafter.
                    </p>
                    <p>
                        3.6&nbsp;&nbsp;When you upload, submit, store, send or receive content to or through our Services, you give us (and those we work with)
                        a worldwide license to use, host, store, reproduce, communicate, publish, publicly display and distribute such content. The rights you grant
                        in this license are for the limited purpose of operating our Services. You retain ownership of any intellectual property rights that you hold in
                        the Content.
                    </p>
                    <p>
                        3.7&nbsp;&nbsp;In respect of your use of the Services you shall:
                    </p>
                    <ol type="a">
                        <li>
                            comply with all laws regulations and obligations applicable to your use of the Services and you may only use the Services for lawful purposes;
                        </li>
                        <li>
                            bear sole responsibility for all Content uploaded by you via the Service;
                        </li>
                        <li>
                            ensure that all Content provided by you is accurate and complete;
                        </li>
                        <li>
                            not use the Services in a way that infringes any third party’s rights and you have obtained all necessary third party copyright, trademark,
                            trade secrets or patent licenses and permissions, or such other licenses or permissions as may be required in connection with any
                            other personal or proprietary rights of any third party (including but not limited to rights of personality and rights of privacy),
                            for any Content uploaded;
                        </li>
                        <li>
                            not upload any Content that is, or which we consider in our sole discretion to be, unlawful, harmful, threatening, abusive,
                            harassing, defamatory, vulgar, offensive, obscene, pornographic, hateful or threatening to any group defined by race, religion,
                            gender, national origin or sexual orientation, or otherwise objectionable, including without limitation blatant expressions of
                            bigotry, prejudice, racism, hatred or profanity or post any obscene, lewd, lascivious, excessively violent, harassing or otherwise
                            objectionable Content;
                        </li>
                        <li>
                            inform us immediately if you have reason to believe your log-in credentials have been compromised or are no longer
                            secure; and do any act, engage in any practice or omit to do any act or engage in any practice that would bring us into disrepute
                            or interferes with the integrity or supply of the Services to all users including, without limitation, introducing any harmful code
                            to any part of the Service
                        </li>
                    </ol>
                    <p></p>
                    <p>
                        3.8&nbsp;&nbsp;Despite our best efforts, occasionally there may be information on our Website and App that contains typographical errors,
                        inaccuracies, or omissions that may relate to service descriptions, pricing, promotions, offers, and availability. We reserve the right to
                        correct any errors, inaccuracies or omissions and to change or update information, refuse to accept Orders or cancel Orders if any information
                        on the Website or App is inaccurate at any time without prior notice.
                    </p>
                </div>

                <div>
                    <h3>4. Online Auction</h3>
                    <p>
                        4.1&nbsp;&nbsp;Once you have successfully registered, you will be eligible to bid online for any auction on offer in relation to an Event.
                        Auctions will typically be in the following formats:
                    </p>
                    <ul type="a">
                        <li>"Single Winner Auction"</li>
                        <p>
                            A Single Winner Auction allows you to bid for the exclusive right to air your static Content during the specified portion of
                            the Event as indicated on the Website or App. Closing times at which bidding will end shall be specified in relation to any bid.
                        </p>
                        <li>"Multi Winner Auction"</li>
                        <p>
                            A Multi Winner Auction allows you to bid to be ranked amongst a fixed number of advertisers permitted to display static Content
                            during a specified portion of the Event as indicated on the Website or App. Closing times at which bidding will end shall be specified
                            in relation to any bid. Once the bid closes the specified number of winning bids shall be ranked from highest bidder to lowest bidder and
                            each bidder will receive a proportional amount of the specified advertising time, with the highest bidder receiving the most time and
                            the lowest bidder receiving the least.
                        </p>
                    </ul>
                    <p></p>
                    <p>
                        A Multi Winner Auction allows you to bid to be ranked amongst a fixed number of advertisers permitted to display static Content during a
                        specified portion of the Event as indicated on the Website or App. Closing times at which bidding will end shall be specified in relation
                        to any bid. Once the bid closes the specified number of winning bids shall be ranked from highest bidder to lowest bidder and each bidder
                        will receive a proportional amount of the specified advertising time, with the highest bidder receiving the most time and the lowest bidder
                        receiving the least.
                    </p>
                    <p>
                        4.2&nbsp;&nbsp;During the timeframe for which an auction is open for bidding, you can bid by clicking the “Bid” button. Each bid
                        shall be in a fixed increment value specified for any particular auction. Your bid must adhere to the bid increment schedule.
                    </p>
                    <p>
                        4.3&nbsp;&nbsp;Each bid shall be final and binding as soon as you click the “Bid” button. You acknowledge that you are bidding
                        in a live auction and you agree that each bid submitted as provided in these terms and conditions is irrevocable and cannot be amended or
                        corrected, even if submitted in error and notified to us. You accept full liability for all bids submitted via your Account (including the
                        liability to pay in full and on time in accordance with the terms and conditions specified here).
                    </p>
                    <p>
                        4.4&nbsp;&nbsp;The highest bidder(s) acknowledged by us will be the successful bidder(s) and we will notify you accordingly
                        if your bid is successful.
                    </p>
                    <p>
                        4.5&nbsp;&nbsp;In the event of any dispute between bidders or in the event of doubt on our part as to the validity of any bid, we will
                        have the final discretion to determine the successful bidder, cancel the sale or to re-offer and resell the Services in dispute. If any
                        dispute arises after the sale, our sales record is conclusive.
                    </p>
                    <p>
                        4.6&nbsp;&nbsp;Posted closing times and displays of auction current time are approximate, we reserves the right to close early or extend auctions
                        at any time at our discretion.
                    </p>
                </div>

                <div>
                    <h3>5. Online Purchase</h3>
                    <p>
                        5.1&nbsp;&nbsp;You will be allowed to purchase advertising slots in advance to display your static Content during the specified portion of
                        the Event as indicated on the Website or App. The price for Fixed Exposure services will be set out on the Website and App.
                    </p>
                    <p>
                        5.2&nbsp;&nbsp;You can place your order for a pre-purchased advertising slot during a specified portion of the Event
                        (the “Order”) by following the directions for purchase on the Website or App.
                    </p>
                </div>

                <div>
                    <h3>6. Price and Payment</h3>
                    <p>
                        6.1&nbsp;&nbsp;You may elect to make payment for the Services you purchase using your Paypal account or by
                        electing to receive an invoice from us for such Services.
                    </p>
                    <p>
                        6.2&nbsp;&nbsp;If you elect to use your Paypal account your payment will be processed through PayPal. We do not have access to
                        or store your payment information. You agree to be bound by PayPal’s terms and conditions and acknowledge we are not liable for any loss
                        (including consequential loss), claims or damages you incur in connection with your use of PayPal’s services.
                    </p>
                    <p>
                        6.3&nbsp;&nbsp;We will either invoice you directly or through a third party provider we work with in your territory.
                        Invoices will be emailed to you within 24 hours of the close of the auction and/or upon placing an Order. Applicable taxes will be added
                        to the price and collection of taxes will take place in accordance with the laws applicable to your purchase.
                    </p>
                    <p>
                        6.4&nbsp;&nbsp;You are responsible for timely payment of all invoices in accordance with timeframe and terms set out in the invoice
                        and in the currency stipulated for the Services on the Website or App. In respect of Fixed Exposure services failure to make payment
                        will result in cancellation of your Order. In respect of Online Auction services, if you are the successful bidder and you do not honor
                        your auction bid you will suspended or permanently banned for using the Services via the Website and App.
                    </p>
                </div>

                <div>
                    <h3>7. Privacy Protection</h3>
                    <p>
                        Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Services. By using our Services,
                        you agree that we can use such data in accordance with our privacy policies.
                    </p>
                </div>

                <div>
                    <h3>8. Order Cancellation and Refunds</h3>
                    <p>
                        8.1&nbsp;&nbsp;In the event that we are unable to deliver your Content in the manner prescribed on the Website or App due to a
                        technical failure or fault on our part then the all amounts paid to us in respect of the content that we were unable to deliver
                        (with the exception of the Deposit which will only be refunded at the time of Account closure) shall be refunded in full via the same
                        method used for payment or if payment has not yet been made the invoice shall be canceled.
                    </p>
                    <p>
                        8.2&nbsp;&nbsp;All refunds made via Paypal will be processed by PayPal and paid to the your PayPal account. The timing of
                        such refunds and/or payments will depend on PayPal's processing times. For more information, please see PayPal's terms and conditions.
                    </p>
                </div>

                <div>
                    <h3>9. Modifying and Terminating our Services</h3>
                    <p>
                        9.1&nbsp;&nbsp;We are constantly changing and improving our Services. We may add or remove functionalities or features,
                        and we may suspend or stop a Service altogether.
                    </p>
                    <p>
                        9.2&nbsp;&nbsp;You can stop using our Services at any time. We may also stop providing Services to you, or add or create new
                        limits to our Services at any time.
                    </p>
                </div>

                <div>
                    <h3>10. Our Warranties, Disclaimers and Indemnities</h3>
                    <p>
                        10.1&nbsp;&nbsp;We provide our Services using a commercially reasonable level of skill and care.
                    </p>
                    <p>
                        10.2&nbsp;&nbsp;Other than as expressly set out in these terms or additional terms, neither we nor those we work
                        with make any specific promises about the Services. For example, we don’t make any commitments about the content
                        within the Services, the specific functions of the Services, or their reliability, availability, or ability to meet
                        your needs. We provide the Services “as is”. We are also not responsible for any equipment failure in respect of the
                        digital marketing displays and related equipment and software that is not owned and operated by us. Our responsibility
                        for equipment failure in respect of the digital marketing displays and related equipment and software that is owned and
                        operated by us is limited to providing a refund in accordance with clause 8.1 above.
                    </p>
                    <p>
                        10.3&nbsp;&nbsp;To the extent permitted by law, we exclude all warranties in relation to merchantability, fitness for a
                        particular purpose and non-infringement.
                    </p>
                    <p>
                        10.4&nbsp;&nbsp;When permitted by law, we will not be responsible for lost profits, revenues, or data, financial losses or indirect,
                        special, consequential, exemplary, or punitive damages.
                    </p>
                    <p>
                        10.5&nbsp;&nbsp;To the extent permitted by law, our total liability for any claims under these terms,
                        including for any implied warranties, is limited to the amount you paid us to use the Services (or, if we choose, to supplying you the S
                        ervices again).
                    </p>
                    <p>
                        10.6&nbsp;&nbsp;In all cases, we will not be liable for any loss or damage that is not reasonably foreseeable.
                    </p>
                    <p>
                        10.7&nbsp;&nbsp;You agree to hold us harmless and indemnify us and our affiliates, officers, agents, and
                        employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms,
                        including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
                    </p>
                </div>

                <div>
                    <h3>11. Viruses, Hacking and Other Offenses</h3>
                    <p>
                        11.1&nbsp;&nbsp;We make every effort to ensure that this Website and App is free from viruses or defects. However,
                        we cannot guarantee that your use of this Website or any websites accessible through it and/or the App will not cause
                        damage to your computer or devices. It is your responsibility to ensure that the right equipment is available to you in order
                        to use the Website and App and screen out anything that may damage it.
                    </p>
                    <p>
                        11.2&nbsp;&nbsp;You must not misuse our Website or App by knowingly introducing anything, which may be malicious or
                        technologically harmful. You must not attempt to gain unauthorised access to our Website, App, the server on which our
                        Website is stored or any server, computer or database connected to our Website or App.
                    </p>
                </div>

                <div>
                    <h3>12. Jurisdiction and Applicable Law</h3>
                    <p>
                        Any dispute arising out of or in connection with this contract, including any claim arising from, or related to, a
                        visit to our Website, use of our App and Service, shall be referred to and finally resolved by arbitration administered by
                        the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International
                        Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference
                        in this clause. This Agreement shall be construed in accordance with the laws of England and Wales applicable therein.
                    </p>
                </div>
            </div>
        );
    }
}

export default TermsAndConditionsPage;