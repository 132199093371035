import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { addToCart } from '../../../actions/CartActions';
import InclusionAPI from '../../../api/Inclusion';
import ManageImage from '../../pages/logo/MangeBrandImage';
import Modal from '../../common/Popup';
import { useSelector } from 'react-redux';
import ImageColourEditor from './SelectColour';

const AddToCart = (props) => {
    const history = useHistory();
    const [isAllowed, setAllowed] = useState(false);
    const [name, setName] = useState('');
    const cartItems = useSelector(state => state.cart.items);
    const isInCart = cartItems.some(item => item.id === props.product.id);
    const [viewColourDialog,setViewColourDialog] = useState(true);

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            const { data: response } = await InclusionAPI.get(props.product.id);
            const customerInclusion = response.data;
            setAllowed(customerInclusion.allowed);
            setName(customerInclusion.name);
        };
        props.product.id && fetchCustomerDetails();
    }, [props.product]);
 
    const handleClickAddToCart = () => {
        if (!isAllowed) {
            NotificationManager.error('You are not allowed to add this product to the cart.');
            return; // Stop execution here if not allowed
        }
        if (props.userStatus.length > 0) {
            return history.push(props.userStatus[0]);
        } else {
            if(!props.user.settings.brandImage){
                NotificationManager.error('A default image should be selected before purchasing.');
                return;
            }
            setViewColourDialog(true);
        }
    };

    const handleAddToCart = (sampleImage, imageColour, colourInverted) => {
        if (isInCart) {
            // Check if adding more exceeds available quantity
            const totalQuantityForProduct = cartItems.reduce((totalQty, cartItem) => {
                if (cartItem.id === props.product.id) {
                  return totalQty + cartItem.cartQty;
                }
                return totalQty;
            }, 0);
            if(!props.product.userAvailableQty){
                props.product.userAvailableQty = props.product.availableQty
            }
            if (totalQuantityForProduct >= props.product.userAvailableQty) {
                NotificationManager.info('Item quantity exceeds available quantity.');
            } else {
                let product = { ...props.product };
                product.defaultImageUrl = props.user.settings.brandImage;
                product.defaultImageId = props.user.settings.brandImageId;
                product.sampleImage = sampleImage;
                product.imageColour = imageColour;
                product.colourInverted = colourInverted;
                props.addToCart(product);
            }
        } else {
            let product = { ...props.product };
            product.userAvailableQty = product.availableQty
            product.defaultImageUrl = props.user.settings.brandImage;
            product.defaultImageId = props.user.settings.brandImageId;
            product.sampleImage = sampleImage;
            product.imageColour = imageColour;
            product.colourInverted = colourInverted;
            props.addToCart(product);
        }
    }

    const btnUpdateImage = (
        <Row className="add-to-cart-wrap">
            <Col xs="12">
                <Button
                    className={`col-12 btn-add-to-cart ${props.userStatus.length === 0 ? '' : props.userStatus[1]} ${
                        isAllowed ? '' : 'invert-color'
                    }`}
                    name="addToCart"
                    variant="success"
                    onClick={handleClickAddToCart}
                    disabled={!isAllowed || props.disabled} // Disable if not allowed
                />
            </Col>
        </Row>
    );

    if(viewColourDialog) {
        return(<ImageColourEditor
            size="l"
            title="Select colour"
            opener={btnUpdateImage}
            originalImagePath={props.user.settings.brandImage}
            addToCartAction={handleAddToCart}
            deviceColourScheme={props.product.deviceColourScheme}
        />);
    }

    if (props.disabled) {
        return <div>{btnUpdateImage}</div>;
    }
    if (props.userStatus[1] === 'no-default-image') {
        return <Modal size="xl" title="Manage Images" opener={btnUpdateImage} body={<ManageImage />} />;
    } else if (isAllowed) {
        return <div>{btnUpdateImage}</div>;
    } else {
        return (
            <Modal
                size="md"
                title="The player approval for your brand required."
                opener={btnUpdateImage}
                body={
                    <p>
                        Thank you for your interest in advertising with {name}. We will contact the player for approval.
                    </p>
                }
                hidePrimaryBtn={true}
            />
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: product => {
            dispatch(addToCart(product));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
