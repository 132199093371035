export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_ENDPOINT_PREFIX = '/frontend/v1';
export const API_FORGOT_PASSWORD = '/forgot-password';
export const API_EMAIL_VERIFICATION = '/email-verification';
export const WEBSOCKET_URI = API_BASE_URL + '/ws';
export const STORAGE_PROVIDER = 'LOCAL' //other values - SESSION, COOKIE

//End points
export const API_FAQ = '/faq';
export const API_ORDER = '/orders';
export const API_INVOICE = '/invoices';
export const API_RECEIPT = '/receipts';

//Brand Image status
export const IMAGE_UPLOAD = 0;
export const IMAGE_DEFAULT = 1;
export const IMAGE_APPROVED = 2;
export const IMAGE_PENDING = 3;
export const IMAGE_REJECTED = 4;
export const IMAGE_ERROR = 5;

export const BRAND_IMAGE_STATUS = {
    0: 'Upload Image',
    2: 'Set Default Image',
    3: 'Image Approval Pending',
    4: 'Image Rejected',
};

//User Account status
export const VERIFIED_STATUS = 'VERIFIED';
export const ACTIVE_STATUS = 'ACTIVE';

//input fields length
export const FIELD_LENGTH = 100;

//Account status
export const ACCOUNT_COMPLETION_STEPS_COUNT = 5;

// Temporary Implementation
const PAYPAL_API_BASE_URL = `${API_BASE_URL}${API_ENDPOINT_PREFIX}/paypal-checkout`;

export const API_PAYPAL_INFO = `${PAYPAL_API_BASE_URL}/client-info`;
export const API_PAYPAL_CREATE_ORDER = `${PAYPAL_API_BASE_URL}/create-order`;
export const API_PAYPAL_CAPTURE_ORDER = `${PAYPAL_API_BASE_URL}/capture-order`;
export const API_PAYPAL_AUTHORIZE_ORDER = `${PAYPAL_API_BASE_URL}/paypal-transaction-complete`;

export const QUADCOLOUR_OPTIONS = [
    { id: 'BKYL', name: 'Black and Yellow' },
    { id: 'BKRD', name: 'Black and Red' },
    { id: 'WHYL', name: 'White and Yellow' },
    { id: 'WHRD', name: 'White and Red' },
    { id: 'BKGR', name: 'Black and Green' },
    { id: 'WHGR', name: 'White and Green' },
    { id: 'BKWH', name: 'Black and White' },
    { id: 'WHBL', name: 'White and Blue' },
    { id: 'YLRD', name: 'Yellow and Red' }
];

export const BICOLOUR_OPTIONS = [
    { id: 'BKYL', name: 'Black and Yellow' },
    { id: 'BKRD', name: 'Black and Red' },
    { id: 'WHYL', name: 'White and Yellow' },
    { id: 'WHRD', name: 'White and Red' },
    { id: 'BKGR', name: 'Black and Green' },
    { id: 'WHGR', name: 'White and Green' },
    { id: 'BKWH', name: 'Black and White' },
    { id: 'WHBL', name: 'White and Blue' },
    { id: 'YLGR', name: 'Yellow and Green' },
    { id: 'YLBL', name: 'Yellow and Blue' },
    { id: 'YLRD', name: 'Yellow and Red' },
    { id: 'GRBL', name: 'Green and Blue' },
    { id: 'BLBK', name: 'Blue and Black' },
];

export const BW_OPTIONS = [
    { id: 'BKWH', name: 'Black and White' }
];

export const ASPECT_RATIOS = {
    RATIO_5_3: {
        value: 5 / 3,
        name: "RATIO_5_3"
    },
    RATIO_37_19: {
        value: 37 / 19,
        name: "RATIO_37_19"
    }
};
